<div class="page-tile__group">
	<label class="page-tile__group-label" for="{{id}}">{{label}}<i *ngIf="isRequired" class="fa fa-asterisk"
			aria-hidden="true"></i></label>
	<input *ngIf="!isNumber" class="page-tile__group-input" autocomplete="new-password" dgTrim="blur" id="{{id}}"
		name="{{name}}" [readonly]="isReadonly" [required]="isRequired" [email]="isEmail"
		maxlength="{{maxLength}}" [(ngModel)]="value" (ngModelChange)="onChanged()" (blur)="onTouched()" />

	<input *ngIf="isNumber" class="page-tile__group-input page-tile__group-input--number" step="any" min="0"
		type="number" numberTrim autocomplete="new-password" id="{{id}}" name="{{name}}" pattern="{{pattern}}"
		[readonly]="isReadonly" [required]="isRequired" [(ngModel)]="value" (ngModelChange)="onChanged()" (blur)="onTouched()" />
</div>
