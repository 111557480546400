import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
//This variables are provide naming for Breadcrumbs, by entities Id

	// Admin
	adminFeedId: number;
	adminFeedLogId: number;
	adminFeedFileId: number;
}
