import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'dg-delete-entity',
	templateUrl: './dg-delete-entity.component.html',
	styleUrls: ['./dg-delete-entity.component.sass']
})
export class DgDeleteEntityComponent implements OnInit {
	@Input() open = true;
	@Input() entityType: string;
	@Input() records: any;
	@Output() close = new EventEmitter<void>();
	recordsLables: string[];
	show = 7;
	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges): void {

		if (changes.records.currentValue) {

			this.records = Object.keys(changes.records.currentValue).map((key) => {
				const currentValue = changes.records.currentValue[key];
				currentValue.show = this.show;
				currentValue.entityName = key;
				return currentValue;
			});
		}
	}

	public onClose() {
		this.close.emit();
	}

	get recordsLength() {
		return this.records.length;
	}

	get recordLabel() {
		return this.recordsLength === 1 ? 'Record' : 'Records';
	}

	onMore(items: any) {
		items.show = items.length;
	}

}
