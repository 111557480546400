import { NgModule } from '@angular/core';
import { DgGridInfoComponent } from './dg-grid-info.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [DgGridInfoComponent],
	exports: [DgGridInfoComponent]
})

export class SharedDgGridInfoModule {
}
