import { Component, Input } from '@angular/core';

@Component({
	selector: 'dg-button-loading',
	templateUrl: './dg-button-loading.component.html',
	styleUrls: ['./dg-button-loading.component.css']
})
export class DgButtonLoadingComponent {

  @Input() title: string;
  @Input() class: string;
  @Input() disabled: boolean;
  @Input() busy: boolean;

}
