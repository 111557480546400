import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DgPaginationComponent } from '../dg-pagination/dg-pagination.component';
import { DgSmartCheckboxState } from '../dg-smart-checkbox/dg-smart-checkbox.component';

@Component({
	selector: 'dg-grid',
	templateUrl: './dg-grid.component.html',
	styleUrls: ['./dg-grid.component.sass']
})
export class DgGridComponent {

	@Input() columns: DgGridColumn[];
	@Input() rows: DgGridRow[];
	@Input() hidePaginationIfSingle: boolean;
	@Input() hidePagination: boolean;
	@Input() tooMuchData: boolean;

	@Output() select: EventEmitter<DgGridRow[]> = new EventEmitter<DgGridRow[]>();
	@Output() rowClick: EventEmitter<DgGridRow> = new EventEmitter<DgGridRow>();

	@ViewChild(DgPaginationComponent) ctrPagination;

	finalRows: DgGridRow[];
	sortColumn: DgGridColumn;

	total = 0;
	page = 1;
	pageSize = 30;
	pageSizeOptions: number[] = [30, 100, 500];

	infoFrom = 0;
	infoTo = 0;
	infoTotal = 0;
	infoTotalFiltered = 0;
	infoFiltered = false;
	infoSelected = 0;

	// enum reference
	columnType = DgGridColumnType;

	selectionMode: DgSmartCheckboxState = DgSmartCheckboxState.None;

	private _state: DgGridStateMachine;

	trackById(index: number, item: any): number {
		return item['id'];
	}

	trackByText(index, item) {
		return item.text;
	}

	ngOnInit() {
		if (this.rows) {
			this._state = new DgGridStateMachine(this.rows, this.page, this.pageSize);
			this._applyState();
		}
	}

	ngOnChanges(changes: any) {
		this.selectionMode = DgSmartCheckboxState.None;
		this.select.emit([]);

		//if (changes && changes.currentValue) {
		this._state = new DgGridStateMachine(this.rows, this.page, this.pageSize);
		this._applyState();
		//}
	}

	onSelectionChanged(row: DgGridRow, event) {

		row.isSelected = !row.isSelected;

		this.select.emit([row]);

		if (this._state.finalRows.every((row: DgGridRow, index: number, rows: DgGridRow[]) => row.isSelected)) {
			this.selectionMode = DgSmartCheckboxState.All;
		}
		else if (this._state.finalRows.every((row: DgGridRow, index: number, rows: DgGridRow[]) => !row.isSelected)) {
			this.selectionMode = DgSmartCheckboxState.None;
		}
		else {
			this.selectionMode = DgSmartCheckboxState.Some;
		}

		this._applyState();

		event.stopPropagation();
	}

	onHeaderClicked(event, column) {
		if (this.page != 1)
			this.page = 1;
		this._state = new DgGridStateMachine(this.rows, 1, this.pageSize);

		for (let i = 0; i < this.columns.length; ++i) {
			if (this.columns[i] != column)
				this.columns[i].sortDirection = undefined;
		}

		this.sortColumn = column;
		this.sortColumn.sortDirection = !this.sortColumn.sortDirection;

		this._state.Sort(this.sortColumn);
		this._applyState();
	}

	onPageChanged(page: number) {
		this.page = page;
		this._state.Page(this.page, this.pageSize);
		this._applyState();
	}

	onPageSizeChanged() {
		this.pageSize = Number(this.pageSize);

		this._state.Page(this.page, this.pageSize);
		this._applyState();
	}

	onSelectionModeChanged(mode): void {
		if (!this.rows)
			return;

		this.selectionMode = mode;

		if (mode == DgSmartCheckboxState.All) {
			this._state.filteredRows.forEach((row: DgGridRow, index: number, rows: DgGridRow[]) => row.isSelected = true);
			this.select.emit(this._state.filteredRows);
		}
		if (mode == DgSmartCheckboxState.None) {
			this._state.filteredRows.forEach((row: DgGridRow, index: number, rows: DgGridRow[]) => row.isSelected = false);
			this.select.emit(this._state.filteredRows);
		}

		this._applyState();
	}

	public onRowClick(event) {
		this.rowClick.emit(event);
	}

	filter(filter: (n: DgGridRow) => boolean) {
		if (this.page != 1)
			this.page = 1;
		this._state = new DgGridStateMachine(this.rows, 1, this.pageSize);

		this._state.Filter(filter);

		this._applyState();
	}

	resetFilter(): void {
		if (this.page != 1)
			this.page = 1;
		this._state = new DgGridStateMachine(this.rows, 1, this.pageSize);
		this._state.ResetFilter();

		this._applyState();
	}

	private _applyState(): void {
		this.finalRows = this._state.finalRows;

		if (this._state.paginationShouldBeUpdated) {
			this.total = this._state.total;
			this.ctrPagination.total = this._state.total;
			this.ctrPagination.size = this.pageSize;
			this.ctrPagination.ngOnChanges();
		}

		const lastPageBorder = this._state.page * this.pageSize;
		var to = (lastPageBorder > this._state.filteredRows.length) ? this._state.filteredRows.length : lastPageBorder;

		this.infoTotalFiltered = this._state.filteredRows.length;
		this.infoTotal = this._state.allRows.length;
		this.infoFiltered = this._state.filtered;
		this.infoFrom = this._state.filteredRows.length > 0 ? (this._state.page - 1) * this.pageSize + 1 : 0;
		this.infoTo = to;
		this.infoSelected = this._state.allRows.filter(x => x.isSelected).length;
	}

}

class DgGridStateMachine {

	// cache layers
	allRows: DgGridRow[];
	filteredRows: DgGridRow[];
	private _sortedRows: DgGridRow[];
	private _pagedRows: DgGridRow[];
	finalRows: DgGridRow[];
	// sort settings used by cache
	private _sortColumn: DgGridColumn;
	// paging settings used by cache
	page = 1;
	total = 1;
	private _pageSize = 3;

	filtered = false;
	paginationShouldBeUpdated = false;

	constructor(allRows: DgGridRow[], page: number, pageSize: number) {
		this.allRows = allRows;
		this.filteredRows = allRows;
		this._sortedRows = allRows;
		this._pagedRows = allRows;
		this.finalRows = allRows;

		this.page = page;
		this.total = this.allRows.length;
		this._pageSize = pageSize;

		this.paginationShouldBeUpdated = true;

		this.filtered = false;

		if (allRows)
			this._page();
	}

	Filter(filter: (n: DgGridRow) => boolean): void {
		this.paginationShouldBeUpdated = false;

		this._filter(filter);
	}

	ResetFilter(): void {
		this.filteredRows = this.allRows;

		this.page = 1;
		this.total = this.filteredRows.length;
		this.filtered = false;
		this.paginationShouldBeUpdated = true;

		this._sort();
	}

	Sort(sortColumn: DgGridColumn): void {
		this.paginationShouldBeUpdated = false;
		this._sortColumn = sortColumn;
		this._sort();
	}

	Page(page: number, pageSize: number): void {
		this.paginationShouldBeUpdated = false;

		if (this._pageSize != pageSize)
			this.paginationShouldBeUpdated = true;

		this.page = page;
		this._pageSize = pageSize;
		this._page();
	}

	private _filter(filter: (n: DgGridRow) => boolean): void {

		this.filteredRows = this.allRows.filter(filter);

		this.page = 1;
		this.total = this.filteredRows.length;
		this.filtered = true;
		this.paginationShouldBeUpdated = true;

		this._sort();
	}

	private _sort(): void {
		this._sortedRows = this.filteredRows;

		if (!this._sortColumn || !this._sortColumn.sortable) {
			this._page();
			return;
		}

		if (this._sortColumn.sort)
			this._sortedRows.sort((n1, n2) => {
				return this._sortColumn.sort(n1, n2, this._sortColumn.sortDirection);
			});
		else
			this._sortedRows.sort((n1, n2) => {
				return this._sortColumn.defaultSort(n1, n2, this._sortColumn.sortDirection);
			});

		this._page();
	}

	private _page(): void {

		this._pagedRows = this._sortedRows.slice(
			(this.page - 1) * this._pageSize,
			(this.page - 1) * this._pageSize + this._pageSize);

		this.finalRows = this._pagedRows;
	}
}

export class DgGridRow {
	id: number;
	text: string;
	tag: any;
	class: string;

	isSelected: boolean;
	isBusy: boolean;

	constructor(id, text, tag) {
		this.text = text;
		this.tag = tag;
		this.id = id;
	}
}

export enum DgGridColumnType {
	Regular = 1,
	Select
}

export class DgGridColumn {
	text: string;
	sortable: boolean;
	type: DgGridColumnType = DgGridColumnType.Regular;
	headerClass: string;
	class: string;
	style: any;
	sortAscStyle: string;
	sortDescStyle: string;

	templateRef: TemplateRef<any>;
	cellTemplate: TemplateRef<any>;

	textProvider: (n: DgGridRow) => string;
	isNumber: (n: string) => boolean;
	sort: (a: DgGridRow, b: DgGridRow, direction: boolean | undefined) => number;
	sortDirection?: boolean;

	tag: any;

	constructor(text, template) {
		this.text = text;
		this.templateRef = template;
		this.sortable = true;
	}

	defaultTextProvider(row: DgGridRow): string {
		return row.text;
	}

	defaultSort(a: DgGridRow, b: DgGridRow, direction: boolean | undefined): number {
		let aText: string | number | undefined = undefined;
		let bText: string | number | undefined = undefined;
		aText = this.textProvider ? this.textProvider(a) : a.text;
		bText = this.textProvider ? this.textProvider(b) : b.text;

		if (!aText)
			aText = '';

		if (!bText)
			bText = '';

		if (this.isNumber(aText)) {
			aText = Number(this.textProvider ? this.textProvider(a) : a.text);
		}

		if (this.isNumber(bText)) {
			bText = Number(this.textProvider ? this.textProvider(b) : b.text);
		}

		if (direction) {
			if (aText > bText) return 1;
			if (aText < bText) return -1;
		}
		else {
			if (aText > bText) return -1;
			if (aText < bText) return 1;
		}

		return 0;
	}
}
