<div #template>
    <ng-content></ng-content>
</div>
<div *ngIf="template.children.length === 0" class="container">
    <div class="nav-wrapper">
        <div *ngFor="let route of breadcrumbs" class="breadcrumb" [ngClass]="{'last': route.terminal}">
            <!-- disable link of last item -->
            <a *ngIf="!route.terminal" href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
            <span *ngIf="route.terminal">{{ route.displayName }}</span>
        </div>
    </div>
</div>
