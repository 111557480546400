import { NgModule } from '@angular/core';
import { DgSmartCheckboxComponent } from './dg-smart-checkbox.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [DgSmartCheckboxComponent],
	exports: [DgSmartCheckboxComponent]
})

export class SharedDgSmartCheckBoxModule {
}
