import { NgModule } from '@angular/core';
import { DgPasswordStrength } from './dg-password-strength.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgPasswordStrength],
	exports: [DgPasswordStrength]
})

export class DgPasswordStrengthModule {
}
