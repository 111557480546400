import { Component, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'dg-tile',
	templateUrl: './dg-tile.component.html',
	styleUrls: ['./dg-tile.component.sass']
})

export class DgTileComponent {

	@Input() busy: boolean;
	@Input() isCloning: boolean;
	@Input() isExporting: boolean;
	@Input() pageTileClass: string;
	@Input() pagePanelClass: string;
	@Input() backToTitle: string;
	@Input() formGroup: UntypedFormGroup;
	@Input() referenceCodeInvalid: boolean;
	@Input() isEmailValid : boolean;
 	@Input() isDiscard: boolean;
	@Input() isClone: boolean;
	@Input() isExport: boolean;
	@Input() isCharge: boolean;
	@Input() isDelete: boolean;
	@Input() isTaxDetails = true;
	@Input() isPermissionsIgnore = false;
	@Input() permissions: string[] = [];

	@Output() submitted: EventEmitter<void> = new EventEmitter<void>();
	@Output() navigateTo: EventEmitter<void> = new EventEmitter<void>();
	@Output() discarded: EventEmitter<void> = new EventEmitter<void>();
	@Output() cloned: EventEmitter<void> = new EventEmitter<void>();
	@Output() exported: EventEmitter<void> = new EventEmitter<void>();
	@Output() delete: EventEmitter<void> = new EventEmitter<void>();

	constructor() {
		this.formGroup = new UntypedFormGroup({});
	}

	public discard() {
		this.discarded.emit();
	}

	public handleKeyupEnter(e: any) {
		if (e.code === 'Enter')
			e.preventDefault();
	}

	public onSubmit() {
		this.submitted.emit();
	}

	public onClone() {
		this.cloned.emit();
	}

	public onExport() {
		this.exported.emit();
	}

	public onDelete() {
		this.delete.emit();
	}

	public backTo() {
		this.navigateTo.emit();
	}

}
