import { Action as NgrxAction, createAction, props } from '@ngrx/store';

export enum AuthActions {
	LOGIN = '[AUTH] Login',
	LOGOUT = '[AUTH] Logout',
	LOGIN_SUCCESS = '[AUTH] Login Success',
	LOGIN_FAILURE = '[AUTH] Login Failure'
}

export class LogIn implements NgrxAction {
	readonly type: any = AuthActions.LOGIN;
	constructor(public payload: any) {}
}

export class LogOut implements NgrxAction {
	readonly type: any = AuthActions.LOGOUT;
	constructor(public payload: any) {}
}

export class LogInSuccess implements NgrxAction {
	readonly type: any = AuthActions.LOGIN_SUCCESS;
	constructor(public payload: any) {}
}

export class LogInFailure implements NgrxAction {
	readonly type: any = AuthActions.LOGIN_FAILURE;
	constructor(public payload: any) {}
}

export type AuthAction = | LogIn |  LogOut | LogInSuccess | LogInFailure;
