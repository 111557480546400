<ng-container *ngIf="isLocal; else dynamic">
	<ng-content></ng-content>
	<div *ngIf="loading" class="dg-busy-overlap">
		<div class="dg-busy-local-spinner">Loading&#8230;</div>
		<!-- <mat-spinner [diameter]="diameter$ | async"></mat-spinner> -->
	</div>
</ng-container>
<ng-template #dynamic>
	<div *ngIf="loading" class="app-spinner">Loading&#8230;</div>
</ng-template>
