/* eslint-disable @typescript-eslint/no-explicit-any */
declare interface Map<K, V> {
	map<R>(callbackfn: (key: K, value: V) => R): R[];
}

Map.prototype.map = function (callbackfn) {
	const res: any[] = [];
	this.forEach((value, key) => res.push(callbackfn(key, value)));
	return res;
};

class EqMap<K, V> implements Map<K, V> {

	get size(): number {
		return this._map.size;
	}

	private readonly _map: Map<K, V>;

	constructor(private readonly comparer: (x: K, y: K) => boolean) {
		this._map = new Map<K, V>();
	}

	[Symbol.iterator](): IterableIterator<[K, V]> {
		return this._map[Symbol.iterator]();
	}

	get [Symbol.toStringTag](): string {
		return this._map[Symbol.toStringTag];
	}

	clear(): void {
		this._map.clear();
	}

	delete(key: K): boolean {
		const keys = this.keys();
		while (true) {
			const res = keys.next();
			if (res.done) return false;
			if (this.comparer(res.value, key)) return this._map.delete(res.value);
		}
	}

	entries(): IterableIterator<[K, V]> {
		return this._map.entries();
	}

	forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
		this._map.forEach(callbackfn, thisArg);
	}

	get(key: K): V | undefined {
		if (!this.has(key)) return undefined;

		const keys = this.keys();
		while (true) {
			const res = keys.next();
			if (res.done) return undefined;
			if (this.comparer(res.value, key)) return this._map.get(res.value);
		}
	}

	has(key: K): boolean {
		const keys = this.keys();
		while (true) {
			const res = keys.next();
			if (res.done) return false;
			if (this.comparer(res.value, key)) return true;
		}
	}

	keys(): IterableIterator<K> {
		return this._map.keys();
	}

	map<R>(callbackfn: (key: K, value: V) => R): R[] {
		return this._map.map(callbackfn);
	}

	set(key: K, value: V): this {
		const keys = this.keys();
		while (true) {
			const res = keys.next();
			if (res.done) {
				this._map.set(key, value);
				break;
			}
			if (this.comparer(res.value, key)) {
				this._map.set(res.value, value);
				break;
			}
		}

		return this;
	}

	values(): IterableIterator<V> {
		return this._map.values();
	}
}
(<any>window).EqMap = EqMap;
