import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, map, takeUntil } from 'rxjs/operators';
import { AdminAuthService } from './auth-service';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IRequester<T> = (url: string, body: any, options: any) => Observable<HttpResponse<T>>;

interface IPromiseRequests {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get<T = any>(url: string, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	post<T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	put<T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	patch<T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delete<T = any>(url: string): Promise<HttpResponse<T>>;
}

@Injectable()
export class HttpAdminAuth {
	constructor(private router: Router,
		private http: HttpClient,
		private amdinAuthService: AdminAuthService
	) {

	}

	private get isAuthenticatedAndExpired(): boolean {
		const adminAuthDetails = this.amdinAuthService.adminAuthDetails;

		return adminAuthDetails?.expired || false;
	}

	private get token(): string | undefined {
		const adminAuthDetails = this.amdinAuthService.adminAuthDetails;

		return adminAuthDetails?.token || undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get<T = any>(url: string, options?: any): Observable<HttpResponse<T>> {
		const extendOptions = this.extendOptions(options);

		return this.makeRequest(
			(url, body, options) => this.http.get<T>(url, extendOptions), url, extendOptions, undefined);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	post<T = any>(url: string, body: any, options?: any): Observable<HttpResponse<T>> {
		const extendOptions = this.extendOptions(options);

		extendOptions.headers = extendOptions.headers.set('Content-Type', 'application/json');

		return this.makeRequest(
			(url, body, options) => this.http.post<T>(url, body, extendOptions),
			url, extendOptions, body);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	put<T = any>(url: string, body: any, options?: any): Observable<HttpResponse<T>> {
		const extendOptions = this.extendOptions(options);
		if (!extendOptions.headers.has('Content-Type') && !body) {
			extendOptions.headers = extendOptions.headers.set('Content-Type', 'application/json');
		}

		return this.makeRequest(
			(url, body, options) => this.http.put<T>(url, body, extendOptions),
			url, extendOptions, body);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	patch<T = any>(url: string, body: any, options?: any): Observable<HttpResponse<T>> {
		const extendOptions = this.extendOptions(options);
		extendOptions.headers = extendOptions.headers.set('Content-Type', 'application/json');

		return this.makeRequest(
			(url, body, options) => this.http.patch<T>(url, body, extendOptions),
			url, extendOptions, body);

	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delete<T = any>(url: string): Observable<HttpResponse<T>> {
		const extendOptions = this.extendOptions();
		return this.makeRequest(
			(url, body, options) => this.http.delete<T>(url, extendOptions),
			url, extendOptions, undefined);
	}

	promise(cancel$?: Subject<void>): IPromiseRequests {
		return {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			get: <T = any>(url: string, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.get<T>(url, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				return lastValueFrom(observable, { defaultValue: <HttpResponse<T>>{ body: undefined } });
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			post: <T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.post<T>(url, body, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				return lastValueFrom(observable, { defaultValue: <HttpResponse<T>>{ body: undefined } });
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			put: <T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.put<T>(url, body, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				return lastValueFrom(observable, { defaultValue: <HttpResponse<T>>{ body: undefined } });
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			patch: <T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.patch<T>(url, body, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				return lastValueFrom(observable, { defaultValue: <HttpResponse<T>>{ body: undefined } });
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			delete: <T = any>(url: string): Promise<HttpResponse<T>> => {
				let observable = this.delete<T>(url);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				return lastValueFrom(observable, { defaultValue: <HttpResponse<T>>{ body: undefined } });
			}
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private extendOptions(options?: any): {
		headers: HttpHeaders;
		observe: 'response';
	} {
		let extendOptions = options;
		if (!extendOptions) {
			extendOptions = {
				observe: 'response'
			};
		} else {
			extendOptions.observe = 'response';
		}

		if (!extendOptions.headers) {
			extendOptions.headers = new HttpHeaders({ 'Authorization': 'bearer ' + this.token });
		} else {
			extendOptions.headers = extendOptions.headers.set('Authorization', 'bearer ' + this.token);
		}

		return extendOptions;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private makeRequest<T = any>(requestAction: IRequester<T>, url: string, options: any, body?: any): Observable<HttpResponse<T>> {

		const timestamp = new Date().getUTCMinutes().toString()
			+ '.' + new Date().getUTCSeconds().toString() + '.' + new Date().getUTCMilliseconds().toString();

		let canRequest = of(true);

		if (this.isAuthenticatedAndExpired) {
			canRequest = this.amdinAuthService.refresh(timestamp).pipe(map(() => {
					 // update token or the request as we just refreshed it
					 options.headers = options.headers.set('Authorization', 'bearer ' + this.token);
					 return true;
				 })
			 );
		}

		return canRequest.pipe(mergeMap(can => {

			if (can) {

				// first attempt of request
				return requestAction(url, body, options).pipe(catchError(initialError => {
					// first attempt errored
					if (initialError && initialError.status === 401) {

						// looks like we have to refresh
						return this.amdinAuthService.refresh(timestamp).pipe(
							mergeMap((success) => {
								options.headers = options.headers.set('Authorization', 'bearer ' + this.token);

								// retry with new token
								return requestAction(url, body, options);

							}), catchError(refreshError => {

								if (refreshError && refreshError.status === 401) {

									// refresh failed
									void this.router.navigate(['/log-in']);
								} else if (refreshError && refreshError.status === 500) {

									// unknown error
								} else if (refreshError) {
									// removing token from UI
									void this.router.navigate(['/log-in']);
								}

								return throwError(() => refreshError);
							})
						);
					} else if (initialError && initialError.status !== 500) {

						return throwError(() => initialError);

					} else {

						// unknown error
						return throwError(() => initialError);
					}

				}));
			} else {

				// refresh token return error, authentication failed
				// redirect to login
				void this.router.navigate(['/log-in']);
				return throwError(() => new Error('need auth'));
			}
		}));
	}
}
