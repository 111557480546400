import { NgModule } from '@angular/core';
import { DgBlockGridComponent } from './dg-block-grid.component';
import { SharedDgPaginationModule } from '../dg-pagination/dg-smart-checkbox.module';
import { SharedDgSmartCheckBoxModule } from '../dg-smart-checkbox/dg-smart-checkbox.module';
import { SharedDgGridInfoModule } from '../dg-grid-info/dg-grid-info.module';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		SharedDgSmartCheckBoxModule, SharedDgPaginationModule, SharedDgGridInfoModule
	],
	declarations: [DgBlockGridComponent],
	exports: [DgBlockGridComponent]
})

export class DgBlockGridModule {
}
