import { IIdentity } from './lookup';

export class Feed implements IIdentity {
	id: number;
	name: string;
	brandId: number;
	mappingConfigId: number;
	enabled: boolean;
	nextProcessTime: Date;
	lastProcessDate: Date;
	lastFiles: number;
	lastErrors: number;
	brandName: string;
	timeZone: Date;
	colors: any;
	feedLogHistory: FeedLog[];
}

export class FeedLog implements IIdentity {
	id: number;
	feedId: number;
	dateStarted: Date;
	files: number;
	errors: number;
	data: FeedLogData;
}

export class FeedLogData {
	dateStarted: Date;
	files: number;
	errors: number;
	messages: FeedLogDataMessage[];
}

export class FeedLogDataMessage  {
	date: Date;
	error: string;
	message: string;
}

export class FeedFile implements IIdentity {
	id: number;
	name: string;
	processStageId: number;
	errors: number;
	dateStarted: Date;
	blobPath: string;
	data: PipelineLogData;
}

export class PipelineLogData {
	errors: number;
	messages: PipelineLogDataMessage[];
}

export class PipelineLogDataMessage {
	error: string;
	message: string;
	stepName: string;
	date: Date;
}