import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'dg-dd-button',
	templateUrl: './dg-dd-button.component.html',
	styleUrls: ['./dg-dd-button.component.css']
})
export class DgDdButtonComponent {
	@Input() title: string;
	@Input() item1_title: string;
	@Input() item2_title: string;
	@Input() isDisabled: boolean;
	public isOpen: boolean;

	@Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();

	public onAddButtonClicked(item: boolean) {
		this.onAdd.emit(item);
	}

	public toggleDropDown() {
		this.isOpen = !this.isOpen;
	}
}