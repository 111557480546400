import { NgModule } from '@angular/core';
import { DgModalComponent } from './dg-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		NgSelectModule
	],
	declarations: [DgModalComponent],
	exports: [DgModalComponent]
})

export class SharedDgModalModule {
}
