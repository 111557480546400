import { Injector } from '@angular/core';

interface DgGlobals {
	appModuleInjector: Injector;
}

declare global {
	interface Window { DgGlobals: DgGlobals; }
}

window.DgGlobals = window.DgGlobals || <DgGlobals>{};
