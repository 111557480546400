import { Pipe, PipeTransform } from '@angular/core';
import { DgTranslator } from './dg-translator.service';

//  try with pure TRUE and refresh

@Pipe({ name: 'tr', pure: false })
export class DgTranslatorPipe implements PipeTransform {
	constructor(private translator: DgTranslator) { }
	transform(value: any, strtr?: any) {
		if (!value) { return; }

		let output = this.translator.get(value);

		if (strtr) {
			for (const key in strtr) {
				output = output.replace(RegExp(`{{${key}}}`, 'g'), strtr[key]);
			}
		}

		return output;
	}
}
