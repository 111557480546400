import { NgModule } from '@angular/core';
import { DgTileInputComponent } from './dg-tile-input.component';
import { SharedDgNumberTrimComponentModule } from '../dg-number-trim/dg-number-trim.module';
import { DgTrimModule } from 'shared/dg-trim';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		DgTrimModule,
		SharedDgNumberTrimComponentModule
	],
	declarations: [DgTileInputComponent],
	exports: [DgTileInputComponent]
})

export class SharedDgTileInputModule {
}
