import { NgModule } from '@angular/core';
import { DgSliderComponent } from './dg-slider.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		NgSelectModule
	],
	declarations: [DgSliderComponent],
	exports: [DgSliderComponent]
})

export class SharedDgSliderModule {
}
