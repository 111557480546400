import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgTileInputComponent),
	multi: true
};

@Component({
	selector: 'dg-tile-input',
	templateUrl: './dg-tile-input.component.html',
	styleUrls: ['./dg-tile-input.component.sass'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DgTileInputComponent {
	
	@Input() id: string;
	@Input() name: string;
	@Input() label: string;
	@Input() pattern: string;
	@Input() isRequired = false;
	@Input() isNumber = false;
	@Input() isReadonly = false;
	@Input() isEmail = false;
	@Input() maxLength = '9999';
	
	value: string;
	
	private _onTouchedCallback: () => void = noop;
	private _onChangeCallback: (_: any) => void = noop;
	
	writeValue(value: any) {
		if (value !== undefined) {
			this.value = value;
		}
	}
	
	registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}
	
	registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}
	
	public onChanged(): void {
		this._onChangeCallback(this.value);
		this._onTouchedCallback();
	}
	
	public onTouched() {
		this._onTouchedCallback();
	}
}
