import { Component, Input } from '@angular/core';

@Component({
	selector: 'dg-gridinfo',
	templateUrl: './dg-grid-info.component.html',
	styleUrls: ['./dg-grid-info.component.sass']
})
export class DgGridInfoComponent {

  @Input() from: number;
  @Input() to: number;
  @Input() total: number;
  @Input() isFiltered: boolean;
  @Input() totalFiltered: number;
  @Input() selected: number;

}
