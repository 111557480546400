import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AgGridModule } from 'ag-grid-angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgSelectModule } from '@ng-select/ng-select';
import { Globals } from 'tools/globals';
import { AppConfig } from 'tools/AppConfig';
import { AppInitializer } from 'tools/AppInitializer';
import { AlertComponent, AlertService } from 'tools/alert/alert.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from 'app-routing.module';
import { AppComponent } from 'app.component';

import { HttpAdminAuth, AdminAuthService } from 'core/auth';
import { BrandsRepository, AdminFeedsRepository, TemplatesRepository } from '+admin-panel/common/repositories';
import { AdminEffects } from '+admin-panel/store/effects/admin-effects';
import { reducers } from '+admin-panel/store/reducers/app.reducer';

import { ToasterService } from 'core/toaster-service';

import { DgInputComponent } from 'shared/dg-input/dg-input.component';
import { GroupByPipe, ArraySortPipe } from 'pipe/pipes';

import { DgGridComponent, DgPasswordStrengthModule, SharedDgCheckBoxModule, SharedDgGridInfoModule, SharedDgPaginationModule, SharedDgSmartCheckBoxModule } from 'shared';
import { DgTranslatorModule } from 'tools/transalator';
import { DgTrimModule } from 'shared/dg-trim';
import { DgCrumbsModule } from 'shared/dg-crumbs';
import { GlobalErrorHandler } from 'core/global-error.handler';
import { SharedModule } from 'shared/shared.module';
import { RequestInterceptor, ResponseInterceptor } from 'core/interceptors';

@NgModule({
	imports: [
		SharedModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NgSelectModule,
		ToastrModule.forRoot({
			timeOut: 10000,
			positionClass: 'toast-top-right',
			preventDuplicates: true,
			closeButton: true
		}),
		DgCrumbsModule,
		DgTrimModule,
		DgPasswordStrengthModule,
		SharedDgCheckBoxModule,
		SharedDgSmartCheckBoxModule,
		SharedDgGridInfoModule,
		SharedDgPaginationModule,
		DgTranslatorModule.forRoot({
			defaultLang: 'en',
			storagePrefix: 'dg-language'
		  }),
		RouterModule.forRoot(AppRoutingModule, {}),
		StoreModule.forRoot(reducers),
		StoreDevtoolsModule.instrument(),
		EffectsModule.forRoot([AdminEffects]),
		AgGridModule
	],
	declarations: [
		AppComponent,
		AlertComponent,
		DgInputComponent,
		GroupByPipe,
		ArraySortPipe,
		DgGridComponent],
	providers: [
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		HttpAdminAuth,
		AppConfig,
		AppInitializer,
		AlertService,
		ToasterService,
		Globals,
		GroupByPipe,
		ArraySortPipe,
		BrandsRepository,
		TemplatesRepository,
		AdminAuthService,
		AdminFeedsRepository,
		{	//appinnitialazer
			provide: APP_INITIALIZER,
			useFactory: (appInitializer: AppInitializer) => () => appInitializer.init(),
			deps: [AppInitializer],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi: true
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
