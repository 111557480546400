import { Component, EventEmitter, Output, Input } from '@angular/core';
import * as Moment from 'moment-timezone';

@Component({
	selector: 'dg-selector',
	templateUrl: './dg-date-selector.component.html',
	styleUrls: ['./dg-date-selector.component.sass']
})
export class DgDateSelectorComponent {

	@Input() field: string;
	selectedYear: number;
	selectedMonth: string;
	selectedMonthName: string;
	years: any;
	months: any;
	firstYear: number;
	lastYear: number;
	selectYearForm: boolean;
	selectMonthForm: boolean;
	selectDateForm: boolean;

	currentYear: number;
	daysOfCurrentMonth: any;
	daysOfPreviousMonth: any;
	daysOfNextMonth: any;

	@Output() result: EventEmitter<string> = new EventEmitter<string>();

	constructor() {
	}

	ngOnInit() {
		this.months = Moment.months();
		this.months.forEach((part, index, theArray) => {
			theArray[index] = theArray[index].toUpperCase().substring(3, 0);
		});
		this.getYears();
		this.selectedYear = this.currentYear;
		this.selectedMonthName = this.months[Number(this.selectedMonth) - 1];
		this.getDays();
		this.selectDateForm = true;
	}

	selectDay(day: number) {
		if (day < 10) {
			const selectedDay = '0'.concat(day.toString());
			this.field = `${this.selectedYear}-${this.selectedMonth}-${selectedDay}`;
		} else {
			this.field = `${this.selectedYear}-${this.selectedMonth}-${day}`;
		}

		this.selectDateForm = true;

		this.result.emit(this.field);
	}

	getYears() {
		const currentDate = this.field ? Moment(this.field, 'L').toDate() : new Date();

		this.currentYear = currentDate.getFullYear();
		const a = this.months.indexOf(Moment(currentDate).format('MMMM').toUpperCase().substring(3, 0)) + 1;

		if (a < 10) {
			this.selectedMonth = '0'.concat(a.toString());
		} else {
			this.selectedMonth = a.toString();
		}
		this.years = [];
		this.firstYear = this.currentYear - 6;
		this.lastYear = this.currentYear + 5;
		for (let i = this.firstYear; i <= this.lastYear; i++) {
			this.years.push(i);
		}
	}

	getBackToMonth() {
		this.selectDateForm = false;
		this.selectMonthForm = true;
	}

	getBackToYear() {
		this.selectMonthForm = false;
		this.selectYearForm = true;
	}

	navigateToPrevYears() {
		this.lastYear = this.firstYear - 1;
		this.firstYear = this.firstYear - 12;
		this.years = [];
		for (let i = this.firstYear; i <= this.lastYear; i++) {
			this.years.push(i);
		}
	}

	navigateToNextYears() {
		this.firstYear = this.lastYear + 1;
		this.lastYear = this.lastYear + 12;
		this.years = [];
		for (let i = this.firstYear; i <= this.lastYear; i++) {
			this.years.push(i);
		}
	}

	goToPrevMonth() {
		if (Number(this.selectedMonth) != 1) {
			const month = this.months[Number(this.selectedMonth) - 2];
			this.showSelectDate(month);
		} else {
			const month = this.months[11];
			this.selectedYear = this.selectedYear - 1;
			this.showSelectDate(month);
		}
	}

	goToNextMonth() {
		if (Number(this.selectedMonth) != 12) {
			const month = this.months[Number(this.selectedMonth)];
			this.showSelectDate(month);
		} else {
			const month = this.months[0];
			this.selectedYear = this.selectedYear + 1;
			this.showSelectDate(month);
		}
	}

	showSelectDate(month: any) {
		this.selectedMonthName = month;
		const i = this.months.indexOf(month) + 1;

		if (i < 10) {
			this.selectedMonth = '0'.concat(i.toString());
		} else {
			this.selectedMonth = i.toString();
		}

		this.getDays();
		this.selectMonthForm = false;
		this.selectDateForm = true;
	}

	getDays() {
		const days = new Date(this.selectedYear, Number(this.selectedMonth), 0).getDate();

		this.daysOfCurrentMonth = [];
		this.daysOfPreviousMonth = [];
		this.daysOfNextMonth = [];
		for (let i = 1; i <= days; i++) {
			this.daysOfCurrentMonth.push(i);
		}
		this.getPreviousMonthDays();
		this.getNextMonthDays();
	}

	getPreviousMonthDays() {
		if (this.selectedMonth != '01') {
			const days = new Date(this.selectedYear, (Number(this.selectedMonth) - 1), 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfPreviousMonth.push(i);
			}
		} else {
			const days = new Date(this.selectedYear - 1, 12, 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfPreviousMonth.push(i);
			}
		}

		const d = this.parseDate(`${this.selectedYear}-${Number(this.selectedMonth)}-${this.daysOfCurrentMonth[0]}`);
		const dayOfWeek = d.toString().substring(3, 0);
		const idx = this.getDayIndex(dayOfWeek);
		if (!idx)
			throw new Error(`Cannot parse say of weel - ${dayOfWeek}`);
		const dayIndex = 7 - idx;

		this.daysOfPreviousMonth = this.daysOfPreviousMonth.slice(this.daysOfPreviousMonth.length - dayIndex,
			this.daysOfPreviousMonth.length);
	}

	checkCurrentYear(year: number) {
		if (this.currentYear == year) {
			return 'period-picker__grid-cell--current';
		} else {
			return '';
		}
	}

	checkCurrentDay(day: number) {
		const formatField = this.field ? Moment(this.field, 'L').toDate() : new Date();
		const currentDate = this.formatDate(formatField);

		if (day < 10) {
			const selectedDay = '0'.concat(day.toString());
			const date = `${this.selectedYear}-${this.selectedMonth}-${selectedDay}`;
			if (currentDate === date) {
				return 'period-picker__grid-cell--current';
			} else {
				return '';
			}
		} else {
			const date = `${this.selectedYear}-${this.selectedMonth}-${day}`;
			if (currentDate === date) {
				return 'period-picker__grid-cell--current';
			} else {
				return '';
			}
		}
	}

	checkCurrentMonth(month: string) {
		if (this.currentYear === this.selectedYear) {
			const date = this.field ? Moment(this.field, 'L').toDate() : new Date();
			const currentMonth = Moment(date).format('MMMM').toUpperCase().substring(3, 0);

			if (currentMonth == month) {
				return 'period-picker__grid-cell--current';
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	getNextMonthDays() {
		let days: any;

		if (this.selectedMonth != '12') {
			days = new Date(this.selectedYear, (Number(this.selectedMonth) + 1), 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfNextMonth.push(i);
			}
		} else {
			days = new Date(this.selectedYear + 1, 1, 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfNextMonth.push(i);
			}
		}
		const d = this.parseDate(`${this.selectedYear}-${Number(
			this.selectedMonth)}-${this.daysOfCurrentMonth[this.daysOfCurrentMonth.length - 1]}`);
		const dayOfWeek = d.toString().substring(3, 0);
		const dayIndex = this.getDayIndex(dayOfWeek);
		if (!dayIndex)
			throw new Error(`Cannot parse say of weel - ${dayOfWeek}`);
		if (this.daysOfPreviousMonth.length + dayIndex > 8) {
			this.daysOfNextMonth = this.daysOfNextMonth.slice(0, dayIndex - 1);
		} else {
			this.daysOfNextMonth = this.daysOfNextMonth.slice(0, dayIndex + 6);
		}
	}

	getDayIndex(day: string): number | undefined {
		switch (day) {
		case 'Sun':
			return 1;
		case 'Sat':
			return 2;
		case 'Fri':
			return 3;
		case 'Thu':
			return 4;
		case 'Wed':
			return 5;
		case 'Tue':
			return 6;
		case 'Mon':
			return 7;
		}
	}

	parseDate(input: any) {
		const parts = input.match(/(\d+)/g);
		return new Date(parts[0], parts[1] - 1, parts[2]);
	}

	navigateToNextYear() {
		this.selectedYear = this.selectedYear + 1;
	}

	navigateToPrevYear() {
		this.selectedYear = this.selectedYear - 1;
	}

	showSelectMonth(year: number) {
		this.selectedYear = year;
		this.selectYearForm = false;
		this.selectMonthForm = true;
	}

	formatDate(date: any) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}
		return [year, month, day].join('-');
	}
}
