import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

class Button {
	page = 0;
	
	constructor(page: number) {
		this.page = page;
	}
}

@Component({
	selector: 'dg-pagination',
	templateUrl: './dg-pagination.component.html',
	styleUrls: ['./dg-pagination.component.sass']
})
export class DgPaginationComponent {
	
	@Input() current = 1;
	@Input() total = 1;
	@Input() size = 1;
	
	@Input() hidePaginationIfSingle: boolean;
	
	@Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
	
	pagesNumber = 1;
	buttons: Button[];
	
	ngOnChanges(changes: any) {
		this.pagesNumber = (this.total - (this.total % this.size)) / this.size
			+ (this.total % this.size > 0 ? 1 : 0);
		
		this.buttons = [];
		for (var _i = 1; _i < this.pagesNumber + 1; _i++) {
			const btn = new Button(_i);
			this.buttons.push(btn);
		}
	}
	
	onChanged(pageNumber: number) {
		if (pageNumber == this.current || pageNumber == 0 || pageNumber > this.pagesNumber)
			return;
		
		this.current = pageNumber;
		
		this.pageChanged.emit(this.current);
	}
	
}
