<ng-container>
	<div *ngIf="selectYearForm">
		<div class="period-picker__group-header">
			<button type="button" class="btn btn--default period-picker__group-header-arrow"
					(click)="navigateToPrevYears()"><i aria-hidden="true"
																				  class="fa fa-chevron-left "></i>
			</button>
			<span class="period-picker__group-header-title">{{firstYear}}-{{lastYear}}</span>
			<button type="button" class="btn btn--default period-picker__group-header-arrow"
					(click)="navigateToNextYears()"><i aria-hidden="true"
																				  class="fa fa-chevron-right"></i>
			</button>
		</div>
		<div class="period-picker__grid">
			<div *ngFor="let year of years" class="period-picker__grid-cell {{checkCurrentYear(year)}}"
				 (click)="showSelectMonth(year)">{{year}}
			</div>
		</div>
	</div>
	<div *ngIf="selectMonthForm">
		<div class="period-picker__group-header">
			<button type="button" class="btn btn--default period-picker__group-header-arrow"
					(click)="navigateToPrevYear()">
				<i aria-hidden="true" class="fa fa-chevron-left "></i>
			</button>
			<button type="button" class="btn btn--default period-picker__group-header-title" (click)="getBackToYear()">
				{{selectedYear}}
			</button>
			<button type="button" class="btn btn--default period-picker__group-header-arrow"
					(click)="navigateToNextYear()">
				<i aria-hidden="true" class="fa fa-chevron-right"></i>
			</button>
		</div>
		<div class="period-picker__grid">
			<div *ngFor="let month of months" class="period-picker__grid-cell {{checkCurrentMonth(month)}}"
				 (click)="showSelectDate(month)">{{month}}
			</div>
		</div>
	</div>
	<div *ngIf="selectDateForm">
		<div class="period-picker__group-header">
			<button type="button" class="btn btn--default period-picker__group-header-arrow" (click)="goToPrevMonth()">
				<i class="fa fa-chevron-left " aria-hidden="true"></i></button>
			<button type="button" class="btn btn--default period-picker__group-header-title" (click)="getBackToMonth()">
				{{selectedMonthName}}, {{selectedYear}}
			</button>
			<button type="button" class="btn btn--default period-picker__group-header-arrow" (click)="goToNextMonth()">
				<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
		</div>
		<div class="period-picker__grid period-picker__grid--days">
			<div *ngFor="let day of daysOfPreviousMonth" class="period-picker__grid-cell period-picker__grid-cell--inactive"
				 (click)="goToPrevMonth()">{{day}}
			</div>
			<div *ngFor="let day of daysOfCurrentMonth" class="period-picker__grid-cell {{checkCurrentDay(day)}}"
				 (click)="selectDay(day)">{{day}}
			</div>
			<div *ngFor="let day of daysOfNextMonth" class="period-picker__grid-cell period-picker__grid-cell--inactive"
				 (click)="goToNextMonth()">{{day}}
			</div>
		</div>
	</div>
</ng-container>
