import { NgModule } from '@angular/core';
import { DgButtonComponent } from './dg-button.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgButtonComponent],
	exports: [DgButtonComponent]
})

export class SharedDgButtonModule {
}
