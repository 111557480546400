<div class="multiple_emails-container">
	<ul *ngIf="multiline" class="multiple_emails-ul">
		<li *ngFor="let item of items; let i = index" class="multiple_emails-email">
			<span class="email_name-closebtn" (click)="remove(i)">×</span>
			<span class="email_name">{{item}}</span>
		</li>
	</ul>
	<input *ngIf="type === 'email'" name="email" class="multiple_emails-input"
		   type="email" email autocomplete="new-password"
		   id="{{id}}" [(ngModel)]="item" (ngModelChange)="onInputValueChange()" (blur)="onTouched()" (keyup.enter)="addItem($event)"
		   (focusout)="addOnFocusOut($event)">
	<input *ngIf="type === 'paymentOptionLines' && items.length < 5" name="optionLine" class="multiple_emails-input"
		   type="text" autocomplete="new-password" id="{{id}}" [(ngModel)]="item"
		   (ngModelChange)="onInputValueChange()" (blur)="onTouched()" (keyup.enter)="addItem($event)">
</div>
