import { NgModule } from '@angular/core';
import { DgDateSelectorComponent } from './dg-date-selector.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgDateSelectorComponent],
	exports: [DgDateSelectorComponent]
})

export class SharedDgDateSelector {
}
