import { Component, OnInit, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AlertService {
	// Observable string sources
	private notificationSource = new Subject<Alert>();

	// Observable string streams
	showAlert$ = this.notificationSource.asObservable();

	// Service message commands
	show(message: string, type: string) {

		const alert = new Alert(message, type);

		this.notificationSource.next(alert);
	}
}

@Component({
	selector: '[alert]',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.sass']
})
export class AlertComponent {
	type: string;
	message: string;

	show: boolean;
	timeoutId: any;

	private hideAfter = 10000; //milliseconds

	constructor(private alertService: AlertService) {

		alertService.showAlert$.subscribe((alert:Alert) => {
			this.show = true;
			this.message = alert.message;
			this.type = alert.type;

			clearTimeout(this.timeoutId);

			this.timeoutId = setTimeout(() => {
				this.show = false;
			}, this.hideAfter);
		});

	}
}

export class Alert {
	constructor(public message: string, public type: string) {
	}
}
