import { Component, Input } from '@angular/core';

@Component({
	selector: 'dg-modal',
	templateUrl: './dg-modal.component.html',
	styleUrls: ['./dg-modal.component.sass']
})
export class DgModalComponent {
	@Input() open = true;
}
