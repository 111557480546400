import { NgModule } from '@angular/core';
import { DgButtonLoadingComponent } from './dg-button-loading.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgButtonLoadingComponent],
	exports: [DgButtonLoadingComponent]
})

export class SharedDgButtonLoadingModule {
}
