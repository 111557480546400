import { NgModule } from '@angular/core';
import { DgMultiEmailComponent } from './dg-multi-email.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [DgMultiEmailComponent],
	exports: [DgMultiEmailComponent]
})

export class SharedDgMultiEmailModule {
}
