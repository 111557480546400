<div #strength id="strength">
	<small class="strengthBar__label">{{barLabel}}</small>
	<ul id="strengthBar">
		<li class="point" [style.background-color]="bar0"></li>
		<li class="point" [style.background-color]="bar1"></li>
		<li class="point" [style.background-color]="bar2"></li>
		<li class="point" [style.background-color]="bar3"></li>
		<li class="point" [style.background-color]="bar4"></li>
	</ul>
</div>
