import { Routes } from '@angular/router';

export const AppRoutingModule: Routes = [
	{
		path: '',
		loadChildren: () => import('./+admin-panel/admin-panel.module').then(m => m.AdminPanelModule)
	},

	{ path: '**', redirectTo: '', pathMatch: 'full' }
];
