import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface AppCfg {
	apiurl: string;
	blobUrl: string;
	portalUrl: string;
}

@Injectable()
export class AppConfig {
	get apiUrl(): string {
		return this.getConfig('apiUrl');
	}

	get portalUrl(): string {
		return this.getConfig('portalUrl');
	}

	get blobUrl(): string {
		return this.getConfig('blobUrl');
	}

	get apiVersion(): string {
		return this.getConfig('apiversion');
	}

	get adminVersion(): string {
		return '/admin';
	}

	private appConfig?: AppCfg;
	constructor(private http: HttpClient) {
	}

	loadConfig(env: string): Observable<AppCfg> {
		return this.http
			.get<AppCfg>('assets/configs/app.' + env + '.json?v=' + Date.now())
			.pipe(
				tap(c => this.appConfig = c),
				catchError((er: HttpErrorResponse) => {
					return throwError(() => new Error((er.error?.error) ? er.error.error : 'Server error'));
				})
			);
	}

	private getConfig(key: string): string {
		if (!this.appConfig)
			throw new Error(`appConfig used before gets initialized!`);

		return this.appConfig[key];
	}
}
