<div *ngIf="!hidePaginationIfSingle || (hidePaginationIfSingle && buttons.length > 1)" class="grid__paging">
	<a class="grid__paging-button grid__paging-button--first grid__paging-button--disabled"
	   [class.grid__paging-button--disabled]="current === 1" (click)="onChanged(1)">First</a>
	<a class="grid__paging-button grid__paging-button--previous grid__paging-button--disabled"
	   [class.grid__paging-button--disabled]="current === 1" (click)="onChanged(current-1)"><i
		class="fa fa-long-arrow-left" aria-hidden="true"></i>&nbsp;Previous</a>
	<!-- FIRST LINK SECTION -->
	<span *ngFor="let button of buttons;">
			<a *ngIf="button.page === 1 && (current-4) >= 0" class="grid__paging-button"
			   [class.grid__paging-button--current]="button.page === current"
			   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === 1 && (current-4) > 0" class="grid__padding-button">...</a>
	</span>
	<!-- CURRENT LINK SECTION -->
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === (current-2) && (current-2) > 0" class="grid__paging-button"
		   [class.grid__paging-button--current]="button.page === current"
		   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === (current-1) && (current-1) > 0" class="grid__paging-button"
		   [class.grid__paging-button--current]="button.page === current"
		   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === current" class="grid__paging-button"
		   [class.grid__paging-button--current]="button.page === current"
		   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === (current+1) && (current) < pagesNumber" class="grid__paging-button"
		   [class.grid__paging-button--current]="button.page === current"
		   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === (current+2) && (current+1) < pagesNumber" class="grid__paging-button"
		   [class.grid__paging-button--current]="button.page === current"
		   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<!-- LAST LINK SECTION -->
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === pagesNumber && (current+3) < pagesNumber" class="grid__padding-button">...</a>
	</span>
	<span *ngFor="let button of buttons;">
		<a *ngIf="button.page === pagesNumber && (current+2) < pagesNumber" class="grid__paging-button"
		   [class.grid__paging-button--current]="button.page === current"
		   (click)="onChanged(button.page)">{{button.page}}</a>
	</span>
	<a class="grid__paging-button grid__paging-button--next"
	   [class.grid__paging-button--disabled]="current === pagesNumber" (click)="onChanged(current+1)">Next&nbsp;<i
		class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
	<a class="grid__paging-button grid__paging-button--last"
	   [class.grid__paging-button--disabled]="current === pagesNumber" (click)="onChanged(pagesNumber)">Last</a>
</div>
