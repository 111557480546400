import { NgModule } from '@angular/core';
import { DgGridControlComponent } from './dg-grid-control.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [DgGridControlComponent],
	exports: [DgGridControlComponent]
})

export class SharedDgGridControlModule {
}
