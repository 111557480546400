<form novalidate autocomplete="do-not-show-ac" class="page-tile {{pageTileClass}}"
	[formGroup]="formGroup" (submit)="formGroup.valid && onSubmit()" (keydown)="handleKeyupEnter($event)">
	<div class="page-tile__panel {{pagePanelClass}}">

		<dg-button [name]="'backTo'" [type]="'button'" title="Back To {{backToTitle}}" (click)="backTo()"><i
				class="fa fa-chevron-left" aria-hidden="true"></i>
		</dg-button>

		<div *ngxPermissionsOnly="permissions">
			<dg-button
				*ngIf="backToTitle !== 'Product Templates' && backToTitle !== 'Kit Products' && backToTitle !== 'Customer Users'"
				[name]="'saveEdit'" [type]="'submit'" [title]="'Save'"
				[disabled]="!formGroup.valid || formGroup.pristine || !isTaxDetails || busy">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="backToTitle === 'Product Templates'" [name]="'saveEdit'" [type]="'submit'"
				[title]="'Save'"
				[disabled]="!formGroup.valid || formGroup.pristine || referenceCodeInvalid || !isCharge || busy">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="backToTitle === 'Kit Products'" [name]="'saveEdit'" [type]="'submit'" [title]="'Save'"
				[disabled]="!formGroup.valid || formGroup.pristine || !isCharge || !isTaxDetails || busy">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="backToTitle === 'Customer Users'" [name]="'saveEdit'" [type]="'submit'" [title]="'Save'"
				[disabled]="!formGroup.valid || formGroup.pristine || isEmailValid || busy">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<dg-button *ngIf="isPermissionsIgnore" [name]="'saveEdit'" [type]="'submit'" [title]="'Save'"
			[disabled]="!formGroup.valid || formGroup.pristine || busy">
			<i class="fa" aria-hidden="true"
				[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
		</dg-button>

		<dg-button *ngIf="isDiscard" title="Discard" [name]="'discard'" [disabled]="formGroup.pristine"
			[type]="'button'" (click)="discard()"><i class="fa fa-undo" aria-hidden="true"></i>
		</dg-button>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="isClone" title="Clone" [name]="'clone'" [disabled]="!formGroup.valid || isCloning"
				[type]="'button'" (click)="onClone()">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-copy': !isCloning, 'fa-spinner fa-spin btn-spinner': isCloning}"></i>
			</dg-button>

		</div>
		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="isExport" title="Export" [name]="'export'" [disabled]="!formGroup.valid || isExporting"
				[type]="'button'" (click)="onExport()">
				<i class="fa fa-share" aria-hidden="true"></i>
			</dg-button>
		</div>
		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="isDelete" title="Delete" [name]="'delete'" [type]="'button'" (click)="onDelete()">
				<i class="fa fa-trash-o" aria-hidden="true"></i>
			</dg-button>
		</div>
	</div>
	<ng-content></ng-content>
</form>
