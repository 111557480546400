import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { DgPaginationComponent } from './dg-pagination.component';

@NgModule({
	imports: [SharedModule],
	declarations: [DgPaginationComponent],
	exports: [DgPaginationComponent]
})

export class SharedDgPaginationModule {
}
