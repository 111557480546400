import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Guid } from 'domain/types';

type SimpleType = string | number | boolean | bigint | symbol | null | undefined;
type Converter<T> = (value: SimpleType) => SimpleType | T;

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

	constructor(
	) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

		return next.handle(request).pipe(
			map(event => {
				if (!(event instanceof HttpResponse)) { return event; }
				return event.clone({ body: this.convertProperties(event.body, this.parseGuids) });
			})
		);
	}

	private convertProperties(data: unknown, ...converters: Converter<unknown>[]): unknown {
		// eslint-disable-next-line no-null/no-null
		if (data === undefined || data === null) {
			return data;
		}

		if (typeof data !== 'object') {
			return this.applyConverters(data as SimpleType, ...converters);
		}

		const objData = data as Record<string, unknown>;
		Object.entries(objData).forEach(([propertyName, val]) => {
			if (typeof val !== 'object') {
				const converted = this.applyConverters(val as SimpleType, ...converters);
				if (converted !== val) {
					objData[propertyName] = converted;
				}
			} else {
				this.convertProperties(val, ...converters);
			}
		});

		return data;
	}

	private applyConverters(value: SimpleType, ...converters: Converter<unknown>[]): SimpleType | unknown {
		return converters.map(convert => convert(value)).find(converted => converted !== value) || value;
	}

	private parseGuids(value: SimpleType): SimpleType | Guid {
		if (typeof value === 'string' && Guid.isValid(value)) {
			return new Guid(value);
		}

		return value;
	}
}
