import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgCheckboxComponent),
	multi: true
};

@Component({
	selector: 'dg-checkbox',
	templateUrl: './dg-checkbox.component.html',
	styleUrls: ['./dg-checkbox.component.css'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DgCheckboxComponent implements ControlValueAccessor {
	
	@Input() id: string;
	@Input() isDisabled = false;
	@Input() isGridCheckbox = false;
	
	@Input() class: string;
	@Input() labelClass: string;
	@Input() inputClass: string;
	
	value: boolean;
	
	private _onTouchedCallback: () => void = noop;
	private _onChangeCallback: (_: any) => void = noop;
	
	writeValue(value: any) {
		if (value !== undefined) {
			this.value = value;
		}
	}
	
	registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}
	
	registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}
	
	public onChanged(): void {
		this._onChangeCallback(this.value);
		this._onTouchedCallback();
	}
	
	public onTouched() {
		this._onTouchedCallback();
	}
	
}
