import { NgModule } from '@angular/core';
import { DgTileSelectComponent } from './dg-tile-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		NgSelectModule
	],
	declarations: [DgTileSelectComponent],
	exports: [DgTileSelectComponent]
})

export class SharedDgTileSelectModule {
}
