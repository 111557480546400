import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'dg-input',
	templateUrl: './dg-input.component.html',
	styleUrls: ['./dg-input.component.css']
})
export class DgInputComponent {

  @Input() id: string;
  @Input() value: string;

  @Output() changed: EventEmitter<DgInputComponent> = new EventEmitter<DgInputComponent>();

  input: UntypedFormControl;

  public saved = false;
  public saving = false;
  private timerId: any;

  constructor() {

  }

  public ngOnInit() {
  	this.input = new UntypedFormControl();
  	this.input.setValue(this.value);
  }

  public onSaved(): void {
  	this.saving = false;
  	this.saved = true;

  	const that = this;

  	clearTimeout(this.timerId);

  	this.timerId = setTimeout(() => {
  		that.saved = false;
  	}, 3000);
  }

  public onBlur(): void {

  	if (this.input.dirty && !this.input.invalid) {

  		this.saving = true;
  		this.saved = false;

  		this.changed.emit(this);
  	}
  }

  public onKeyPress(): void {
  	clearTimeout(this.timerId);
  	this.saving = false;
  	this.saved = false;
  }

}
