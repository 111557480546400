import { NgModule, ModuleWithProviders } from '@angular/core';
import { DgTranslatorPipe } from './dg-translator.pipe';

export interface DgTranslatorConfig {
	defaultLang?: string;
	storagePrefix?: string;
}

@NgModule({
	declarations: [DgTranslatorPipe],
	exports: [DgTranslatorPipe]
})

export class DgTranslatorModule {
	public static forRoot(config: DgTranslatorConfig): ModuleWithProviders<DgTranslatorModule> {
		return {
			ngModule: DgTranslatorModule,
			providers: [
				{ provide: 'config', useValue: config }
			]
		};
	}
}
