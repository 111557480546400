<div *ngIf="open" class="modal modal--tile">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title"> <b>{{recordLabel}} in Use</b></div>
				<div class="page-tile__group">
					<span> {{recordsLength > 1 ? 'These' : 'This'}} {{recordLabel | lowercase}} {{recordsLength > 1 ?
						'are' : 'is'}} associated with
						following references:</span>
				</div>
				<div class="page-tile__content">
					<div *ngFor="let items of records" class="page-tile__content-item">
						<span [hidden]="recordsLength === 1">{{items.entityName}} {{entityType}}:</span>
						<div *ngFor="let row of items | slice:0:items.show; let i=index" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							<div [ngSwitch]="$any(row)?.entityType">
								<div *ngSwitchCase="'Rate Card Record'">
									Rate Card "{{$any(row)?.rateCard}}" Record "{{$any(row)?.entityName}}"
								</div>
								<div *ngSwitchCase="'Price Book Product'">
									Price Book "{{$any(row)?.priceBook}}" Product "{{$any(row)?.entityName}}"
								</div>
								<div *ngSwitchDefault>
									{{$any(row)?.entityType}} "{{$any(row)?.entityName}}" {{($any(row)?.customerName && $any(row)?.entityType
										!== 'Customer') ? 'of customer "' + $any(row)?.customerName + '"' : ''}}
								</div>
							</div>

						</div>
						<div *ngIf="items.show < items.length" class="page-tile__content-more" (click)="onMore(items)">
							More ...</div>
					</div>
				</div>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" title="Close" (click)="onClose()">Close
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="open" class="fade"></div>
