<div class="sidebar" [ngClass]="{'sidebar--open': open, 'sidebar--close': !open}">
	<div *ngIf="!hideButtonIsHidden" class="sidebar__title">
		<span>{{title}}</span>
		<button class="sidebar__hide" (click)="onCancelClicked()">Hide <i class="fa fa-chevron-right"
																		  aria-hidden="true"></i></button>
	</div>
	<div *ngIf="open">
		<ng-content></ng-content>

	</div>
</div>
