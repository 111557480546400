import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { InvoiceNumberNextValidator } from './invoice-number-next-validator';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [InvoiceNumberNextValidator],
	exports: [InvoiceNumberNextValidator]
})

export class SharedDgInvoiceNumberNextValidatorModule {
}
