import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

import { DgCrumbsService } from './dg-crumbs.service';
import { DgCrumbsComponent } from './dg-crumbs.component';
import { SharedModule } from 'shared/shared.module';

export function breadcrumbServiceFactory(router: Router) {
	return new DgCrumbsService(router);
}

@NgModule({
	imports: [SharedModule, RouterModule],
	providers: [
		{ provide: DgCrumbsService, useFactory: breadcrumbServiceFactory, deps: [Router] }
	],
	declarations: [DgCrumbsComponent],
	exports: [DgCrumbsComponent]
})
export class DgCrumbsModule { }
