import { Injectable } from '@angular/core';

import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Guid } from 'domain/types';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const guid = Guid.generate();
		const modified = req.clone({ setHeaders: { 'X-Request-Id': guid.toString() } });
		return next.handle(modified);
	}
}
