import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey('CompanyName=DATAGATE INNOVATION LIMITED,LicensedApplication=Datagate Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030956,SupportServicesEnd=20_September_2023_[v2]_MTY5NTE2NDQwMDAwMA==c5a4ab1206d9802d999ff495d9873e75');

import './extensions/window';
import './extensions/array';
import './extensions/map';
import './extensions/promise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.env === 'production') {
	enableProdMode();
	if (window) {
		window.console.log = () => { };
	}
}

platformBrowserDynamic().bootstrapModule(AppModule)
	// eslint-disable-next-line no-console
	.catch(err => console.error(err));
