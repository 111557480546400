import { Busy, wrapBusy } from 'shared/modules/busy';

declare global {
	interface Promise<T> {
		/**
		 * Wraps Promise and applies a busy spinner overlay until resolution and/or rejection of the Promise.
		 * @param ref Optional reference to Busy component container which will be used to show local spinner.
		 * @returns An initial Promise.
		 */
		withBusy(ref?: Busy): Promise<T>;
	}
}

Promise.prototype.withBusy = function <T>(ref?: Busy): Promise<T> {
	return wrapBusy(this, ref);
};
