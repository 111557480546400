import { NgModule } from '@angular/core';
import { DgNumberTrimComponent } from './dg-number-trim.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgNumberTrimComponent],
	exports: [DgNumberTrimComponent]
})

export class SharedDgNumberTrimComponentModule {
}
