import { InputTrimDirective as DgTrimDirective } from './dg-trim.directive';
import { NgModule } from '@angular/core';

@NgModule( {
	imports     : [],
	exports     : [DgTrimDirective],
	declarations: [DgTrimDirective],
	providers   : [],
} )
export class DgTrimModule {
}
