import { NgModule } from '@angular/core';
import { DgDeleteEntityComponent } from './dg-delete-entity.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgDeleteEntityComponent],
	exports: [DgDeleteEntityComponent]
})

export class SharedDgDeleteEntityModule {
}
