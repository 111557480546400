// Server Api conventions
export type ApiError = {
	errorCode: number;
	userMessage?: string;
	references?: string[];
};
export type ApiOperation = {
	operationId: number;
	status?: number;
	type?: string;
	uid?: string;
};
export type Api<T> = T & Partial<ApiError>;
export type ApiData<T> = { data: Api<T>; } & Partial<ApiError>;
export type ApiDataPaging<T> = ApiData<T> & {
	page: number;
	pageSize: number;
	pages: number;
	records: number;
};
export type ApiDataError<T> = ApiData<T> & { error?: ApiError; } & Partial<ApiError>;

export function hasApiError(entity: unknown): entity is ApiError {
	return !!entity &&
		typeof entity === 'object' &&
		'errorCode' in entity;
}
