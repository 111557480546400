import { NgModule } from '@angular/core';
import { DgCheckboxComponent } from './dg-checkbox.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [DgCheckboxComponent],
	exports: [DgCheckboxComponent]
})

export class SharedDgCheckBoxModule {
}
