import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Feed, FeedLog, FeedFile } from 'domain/models';
import { HttpAdminAuth } from 'core/auth';
import { AppConfig } from 'tools/AppConfig';
import { ApiData } from 'domain/types';

@Injectable()
export class AdminFeedsRepository {

	constructor(
		private http: HttpAdminAuth,
		private config: AppConfig
	) {
	}
	adminVersion;
	getAll(): Observable<Feed[]> {
		return this.http.get<ApiData<Feed[]>>(`${this.config.apiUrl}/admin/feeds`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	getFeedById(id: number): Observable<Feed | undefined> {
		return this.getAll().pipe(map(feeds => feeds.find(x => x.id === id)));
	}

	getFeedLogs(feedId: number): Observable<FeedLog[]> {
		return this.http.get<ApiData<FeedLog[]>>(`${this.config.apiUrl}/admin/feeds/${feedId}/logs`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	getFeedLog(feedId: number, feedLogId: number): Observable<FeedLog> {
		return this.http.get(`${this.config.apiUrl}/admin/feeds/${feedId}/logs/${feedLogId}`)
			.pipe(
				map(res => res.body.data)
			);
	}

	getFeedFiles(feedId: number): Observable<FeedFile[]> {
		return this.http.get<ApiData<FeedFile[]>>(`${this.config.apiUrl}/admin/feeds/${feedId}/files`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	getFeedFile(feedId: number, feedFileId: number): Observable<FeedFile | undefined> {
		return this.http.get<ApiData<FeedFile>>(`${this.config.apiUrl}/admin/feeds/${feedId}/files/${feedFileId}`)
			.pipe(
				map(res => res.body?.data)
			);
	}

	getFeedFileBlob(path: string): Observable<HttpResponse<Blob>> {

		const options = {
			responseType: 'blob'
		};

		return this.http.post(`${this.config.apiUrl}/admin/feeds/files/download`, { blobPath: path }, options);
	}

	cloneFeed(feedId: number, feed: Feed): Observable<Feed | undefined> {
		return this.http.post<ApiData<Feed>>(`${this.config.apiUrl}/admin/feeds/${feedId}/clone`, feed, undefined)
			.pipe(
				map(res => res.body?.data)
			);
	}

}
