import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService {
	private configError: Partial<IndividualConfig> = {
		disableTimeOut: true,
		easeTime: 150
	};
	private configWarning: Partial<IndividualConfig> = {
		timeOut: 10000
	};
	private configSuccess: Partial<IndividualConfig> = {
		timeOut: 5000
	};
	private configInfo: Partial<IndividualConfig> = {
		timeOut: 15000,
		progressBar: true,
		tapToDismiss: false,
		enableHtml: true,
		closeButton: true
	};

	constructor(
		private toastrService: ToastrService
	) {
	}

  	/**
	 * Displays a success toast with the specified message, title, and configuration.
	 * @param message - The message to be displayed in the toast.
	 * @param title - The title to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	success<TPayload>(message?: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays a success toast with the specified message and configuration, using the default title.
	 * @param message - The message to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	success<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
  	/**
	 * Displays a success toast with the specified configuration, using the default message and title.
	 * @param config - The configuration options for the toast.
	 */
	success<TPayload>(config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
  	/**
	 * Displays a success toast, using the default message, title and configuration.
	 */
	success<TPayload>(): ActiveToast<TPayload>;
	success<TPayload>(message?: string | Partial<IndividualConfig<TPayload>>, titleOrConfig?: string | Partial<IndividualConfig<TPayload>>, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const successMsg = (typeof message === 'string' ? message : undefined) ?? 'Success!';
		const titleText = (typeof titleOrConfig === 'string' ? titleOrConfig : undefined) ?? 'Success';
		const mergedConfig = (typeof message !== 'string' && message) || (typeof titleOrConfig !== 'string' && titleOrConfig) || config || {};

		return this.toastrService.success<TPayload>(successMsg, titleText, { ...this.configSuccess, ...mergedConfig });
	}

	/**
	* Displays an info toast with the specified message, title, and configuration.
	* @param message - The message to be displayed in the toast.
	* @param title - The title to be displayed in the toast.
	* @param config - The configuration options for the toast.
	*/
	info<TPayload>(message: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	* Displays an info toast with the specified message and configuration, using the default title.
	* @param message - The message to be displayed in the toast.
	* @param config - The configuration options for the toast.
	*/
	info<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	info<TPayload>(message: string, titleOrConfig?: string | Partial<IndividualConfig<TPayload>> | undefined, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const isTitleDefined = typeof titleOrConfig === 'string' || titleOrConfig === undefined;
		const titleText = isTitleDefined ? (<string>titleOrConfig || 'Info') : 'Info';
		const mergedConfig = isTitleDefined ? config : titleOrConfig;

		return this.toastrService.info<TPayload>(message, titleText, { ...this.configInfo, ...mergedConfig });
	}

	/**
	 * Displays a warning toast with the specified message, title, and configuration.
	 * @param message - The message to be displayed in the toast.
	 * @param title - The title to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	warning<TPayload>(message: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays a warning toast with the specified message and configuration, using the default title.
	 * @param message - The message to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	warning<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	warning<TPayload>(message: string, titleOrConfig?: string | Partial<IndividualConfig<TPayload>> | undefined, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const isTitleDefined = typeof titleOrConfig === 'string' || titleOrConfig === undefined;
		const titleText = isTitleDefined ? (<string>titleOrConfig || 'Warning') : 'Warning';
		const mergedConfig = isTitleDefined ? config : titleOrConfig;

		return this.toastrService.warning<TPayload>(message, titleText, { ...this.configWarning, ...mergedConfig });
	}

	/**
	 * Displays an error toast with the specified message, title, and configuration.
	 * @param message - The message to be displayed in the toast.
	 * @param title - The title to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	error<TPayload>(message?: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays an error toast with the specified message and configuration, using the default title.
	 * @param message - The message to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	error<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays an error toast with the specified configuration, using the default message and title.
	 * @param config - The configuration options for the toast.
	 */
	error<TPayload>(config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays an error toast, using the default message, title and configuration.
	 */
	error<TPayload>(): ActiveToast<TPayload>;
	error<TPayload>(message?: string | Partial<IndividualConfig<TPayload>>, titleOrConfig?: string | Partial<IndividualConfig<TPayload>>, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const errorMsg = (typeof message === 'string' ? message : undefined) ?? 'Ooops, something went wrong...';
		const titleText = (typeof titleOrConfig === 'string' ? titleOrConfig : undefined) ?? 'Error';
		const mergedConfig = (typeof message !== 'string' && message) || (typeof titleOrConfig !== 'string' && titleOrConfig) || config || {};

		return this.toastrService.error<TPayload>(errorMsg, titleText, { ...this.configError, ...mergedConfig });
	}

	/**
     * Remove all or a single toast by id
     */
	clear(toastId?: number): void {
		this.toastrService.clear(toastId);
	}
}
