import { Injectable } from '@angular/core';
import * as Moment from 'moment-timezone';
import { AppConfig } from './AppConfig';
import { environment } from '@environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class AppInitializer {

	constructor(
		private config: AppConfig
	) { }

	async init(): Promise<boolean> {
		const browserLang = window.navigator.language;
		Moment.locale(browserLang);

		await lastValueFrom(this.config.loadConfig(environment.env));

		return true;
	}
}
