import { NgModule } from '@angular/core';
import { DgDdButtonComponent } from './dg-dd-button.component';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [DgDdButtonComponent],
	exports: [DgDdButtonComponent]
})

export class SharedDgDdButtonModule {
}
