<div class="grid">
	<table class="grid__table grid__table--no-footer">
		<thead class="grid__table--thead">
		<tr>
			<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
			<th *ngFor="let col of columns;" class="{{col.headerClass + ' ' + (col.sortDirection === true ? col.sortAscStyle : '') + ' ' + (col.sortDirection === false ? col.sortDescStyle : '')}}" [ngStyle]="col.style" (click)="onHeaderClicked($event, col)" >
				<ng-container [ngSwitch]="col.type">
					<ng-container  *ngSwitchCase="columnType.Regular">
						<ng-template *ngIf="col.templateRef" [ngTemplateOutletContext]="{ $implicit: col }" [ngTemplateOutlet]="col.templateRef" ></ng-template>
						<span *ngIf="!col.templateRef" [innerHTML]="col.text"></span>
					</ng-container>
					<ng-container  *ngSwitchCase="columnType.Select">
						<dg-smartcheckbox [mode]="selectionMode" (modeChanged)="onSelectionModeChanged($event)"></dg-smartcheckbox>
					</ng-container>
				</ng-container>

			</th>
		</tr>
		</thead>
		<tbody class="grid__table--body">
		<tr *ngFor="let item of finalRows;trackBy:trackById" class="{{item.class}}" (click)="onRowClick(item)">
			<td *ngFor="let col of columns;trackBy:trackByText" class="{{col.class}}" >
				<ng-container [ngSwitch]="col.type">
					<ng-container  *ngSwitchCase="columnType.Regular">
						<ng-template *ngIf="col.cellTemplate" [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="col.cellTemplate" ></ng-template>
						<span *ngIf="!col.cellTemplate" [innerHTML]="col.textProvider(item)"></span>
					</ng-container>
					<ng-container  *ngSwitchCase="columnType.Select">
						<div><input type="checkbox" [(ngModel)]="item.isSelected" (click)="onSelectionChanged(item, $event)"><label class="checkbox" ></label>
						</div>
					</ng-container>
				</ng-container>
			</td>
		</tr>
		<tr *ngIf="(!finalRows || finalRows.length === 0) && tooMuchData !== true" class="grid__row odd">
			<td valign="top" colspan="99" class="grid__cell grid__cell--empty">No Data to Display</td>
		</tr>
		<tr *ngIf="tooMuchData === true">
			<td valign="top" colspan="99" class="grid__cell grid__cell--empty">Too many records to display on page</td>
		</tr>
		</tbody>
	</table>
	<div class="bottom" [ngClass]="{ 'hidden': hidePagination }">
		<div class="grid__page-size">
			<label>Show <select class="dg0-select grid__page-size-select" [(ngModel)]="pageSize" (change)="onPageSizeChanged()">
				<option *ngFor="let opt of pageSizeOptions">{{opt}}</option>
			</select> entries</label>
		</div>
		<dg-gridinfo [from]="infoFrom" [to]="infoTo" [totalFiltered]="infoTotalFiltered" [isFiltered]="infoFiltered" [total]="infoTotal" [selected]="infoSelected" ></dg-gridinfo>
		<dg-pagination #ctrPagination [hidePaginationIfSingle]="hidePaginationIfSingle" [current]="page" [total]="total" [size]="pageSize" (pageChanged)="onPageChanged($event)"></dg-pagination>
	</div>
</div>
