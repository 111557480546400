import { ChangeDetectorRef, ComponentRef, ViewContainerRef } from '@angular/core';
import { Busy } from './busy';

export async function wrapBusy<T>(task: Promise<T>, container?: Busy): Promise<T> {
	const cdr = window.DgGlobals.appModuleInjector.get(ChangeDetectorRef);
	let dynamicBusyComponent: ComponentRef<Busy> | undefined = undefined;
	return new Promise<T>((resolve, reject) => {
		// Postpone to macrotask to avoid ExpressionChangedAfterItHasBeenCheckedError
		// due to creating a view in parent module
		setTimeout(() => {
			if (!container) {
				const vcr = window.DgGlobals.appModuleInjector.get(ViewContainerRef);
				dynamicBusyComponent = vcr.createComponent(Busy);
				container = dynamicBusyComponent.instance;
				container.isLocal = false;

				if (!container) {
					reject('Busy container is not defined.');
					return;
				}
			}

			container.show();
			cdr.detectChanges();
			task.then(result => resolve(result))
				.catch(err => reject(err))
				.finally(() => {
					container?.hide();
					dynamicBusyComponent?.destroy();
				});
		});
	});
}
