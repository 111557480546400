import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'dg-grid-control',
	templateUrl: './dg-grid-control.component.html',
	styleUrls: ['./dg-grid-control.component.sass']
})
export class DgGridControlComponent {
	@Input() storageValue = '';
	@Output() onFilterChanged: EventEmitter<string> = new EventEmitter<string>();
	
	public onFilterTextBoxChanged(value: string) {
		this.onFilterChanged.emit(value);
		
	}
}
