import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'dg-button',
	templateUrl: './dg-button.component.html',
	styleUrls: ['./dg-button.component.css']
})
export class DgButtonComponent {

  @Input() name: string;
  @Input() type: string;
  @Input() title: string;
  @Input() class: string;
  @Input() disabled: boolean;
  @Input() busy: boolean;

  @Output() click: EventEmitter<void> = new EventEmitter<void>();
}
