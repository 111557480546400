import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgMultiEmailComponent),
	multi: true
};

@Component({
	selector: 'dg-multiemail',
	templateUrl: './dg-multi-email.component.html',
	styleUrls: ['./dg-multi-email.component.sass'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class DgMultiEmailComponent {

  @Input() id: string;
  @Input() type: string;

  public multiline = false;

  public item: string;
  public items: string[] = [];
  private listWasCreated = false;

  constructor() {}

  public ngOnInit() {
  	if (this.items.length > 1) {
  		this.listWasCreated = true;
  	}
  }

  private _onTouchedCallback: () => void = noop;
  private _onChangeCallback: (_: any) => void = noop;

  writeValue(value: any) {
  	if (value !== undefined) {
  		this.value = value;
  	}
  }

  registerOnChange(fn: any) {
  	this._onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
  	this._onTouchedCallback = fn;
  }

  public get value(): string {
  	if (this.items.length == 0) {
  		return '';
  	} else {
  		return this.items.join(';');
  	}

  }

  public set value(val: string) {
  	if (!val) {
  		this.items = [];
  		return;
  	}

  	const items = val.split(';');
  	const tmp: string[] = [];
  	if (this.type == 'email') {
  		for (const item of items) {
  			tmp.push(item.toLowerCase());
  		}
  	} else {
  		for (const item of items) {
  			tmp.push(item);
  		}
  	}
  	this.items = tmp;

  	// simple input mode if one email
  	if (this.items.length > 1) {
  		this.multiline = true;
  		this.item = '';
  	} else if (this.items.length == 1) {
  		this.multiline = false;
  		this.item = this.items[0];
  	}
  }

  public onInputValueChange(): void {

  	// single input mode
  	if (!this.multiline) {
  		// queue
  		setTimeout(() => {
  			this._onChangeCallback(this.item);
  			this._onTouchedCallback();
  		}, 1);

  	}
  }

  public addOnFocusOut(event) {
  	if (this.listWasCreated && this.item !== undefined) {
  		this.addItem(event);
  	}
  }

  public addItem(event): void {
  	event.stopPropagation();
  	event.preventDefault();

  	let isValidEmail = true;
  	if (this.type == 'email') {
  		var sanitizedValue = this.item.trim().toLowerCase();
  		isValidEmail = /(.+)@(.+){2,}\.(.+){2,}/.test(this.item);
  	} else {
  		var sanitizedValue = this.item.trim();
  	}

  	if (!this.item || !isValidEmail || sanitizedValue.length == 0)
  		return;

  	if (this.multiline) {

  		// ignore duplicates in multiline mode
  		if (this.items.some(x => x == sanitizedValue)) {
  			this.item = '';
  			return;
  		}

  		this.items.push(sanitizedValue);
  		this.item = '';
  		this.listWasCreated = true;

  		this._onChangeCallback(this.value);

  	} else {

  		// single line mode adds a new value into array only if it was empty initially
  		if (this.items.length == 0) {
  			this.items.push(sanitizedValue);
  		}

  		//assign edited value to first item
  		if (this.items.length == 1) {
	      this.items[0] = sanitizedValue;
  		}

  		this.item = '';
  		this.multiline = true;
  	}

  	this._onTouchedCallback();
  }

  public remove(index: number): void {
  	this.items.splice(index, 1);
  	this.items = this.items.slice();

  	this._onChangeCallback(this.value);
  	this._onTouchedCallback();

  	if (this.items.length == 0) {
  		this.multiline = false;
  	}
  }

  public onTouched() {
  	this._onTouchedCallback();
  }

}
