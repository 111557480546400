import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DgTileComponent } from './dg-tile.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedDgButtonModule } from '../dg-button/dg-button.module';
import { SharedModule } from 'shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		RouterModule,
		SharedDgButtonModule,
		NgxPermissionsModule.forChild()],
	declarations: [DgTileComponent],
	exports: [DgTileComponent]
})

export class SharedDgTileModule {
}
