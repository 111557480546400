import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[invoiceNumberNextValidator]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => InvoiceNumberNextValidator), multi: true }
	]
})

export class InvoiceNumberNextValidator implements Validator, OnChanges {
	
	private _onChange: () => void;
	
	validator: ValidatorFn;
	
	@Input() originalValue: number;
	
	ngOnChanges(changes: SimpleChanges): void {
		
		if ('originalValue' in changes) {
			this._createValidator();
			if (this._onChange) {
				this._onChange();
			}
		}
	}
	
	validate(c: UntypedFormControl) {
		
		if (!this.originalValue) {
			return null;
		}
		return this.validator(c);
	}
	
	registerOnValidatorChange(fn: () => void): void {
		this._onChange = fn;
	}
	
	private _createValidator(): void {
		this.validator = invoiceNumberNextValidatorFactory(this.originalValue);
	}
}

function invoiceNumberNextValidatorFactory(originalValue: number): ValidatorFn {
	return (c: AbstractControl) => {
		
		if (!c) {
			return null;
		}
		const isInvalid = parseInt(c.value, 10) < originalValue;
		
		if (isInvalid) {
			return {
				greater: {
					valid: false
				}
			};
		} else {
			return null;
		}
	};
}
