import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ApiError, hasApiError } from 'domain/types';
import { ToasterService } from './toaster-service';

class ErrorOutput {
	title = 'Unhandled Error';
	alert = 'Unknown Error';
	stack = undefined;

	setApiError(apiError: ApiError): void {
		this.title = this.resolveApiErrorTitle(apiError);
		this.alert = `${apiError.userMessage}`;
		if (apiError.references && typeof apiError.references === 'string') {
			this.alert += '\n' + apiError.references;
		}
		this.stack = undefined;
	}

	private resolveApiErrorTitle(apiError: ApiError): string {
		let title = `Api Error ${apiError.errorCode}`;

		if ((apiError.errorCode >= 3000 && apiError.errorCode < 4000) ||
			(apiError.errorCode >= 4000 && apiError.errorCode < 5000) ||
			(apiError.errorCode >= 5000 && apiError.errorCode < 6000) ||
			(apiError.errorCode >= 7000 && apiError.errorCode < 8000)) {
			title = `Validation Error`;
		}

		return title;
	}
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

	constructor(
		@Inject(Injector) private readonly injector: Injector
	) {
		super();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async handleError(error: any): Promise<void> {
		super.handleError(error);

		// Workaround for ChunkLoadError
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message)) {
			window.location.reload();
			return;
		}

		const errorOutput = new ErrorOutput();

		if (hasApiError(error)) {
			errorOutput.setApiError(error);
		} else if (error.rejection) {
			// It is failed Promise
			let apiError: ApiError | undefined = undefined;
			if (hasApiError(error.rejection)) {
				apiError = error.rejection;
			} else if (hasApiError(error.rejection.error)) {
				apiError = error.rejection.error;
			} else if (hasApiError(error.rejection.error?.data)) {
				apiError = error.rejection.error;
			} else if (hasApiError(error.rejection.data?.data)) {
				apiError = error.rejection.data;
			}

			if (apiError) {
				errorOutput.setApiError(apiError);
			} else if (typeof error.rejection === 'object') {
				errorOutput.alert = `${error.rejection.name}\n${error.rejection.message}`;
				errorOutput.stack = error.rejection.stack;
			} else {
				errorOutput.alert = `${error.rejection}`;
				errorOutput.stack = error.stack;
			}
		} else if (error instanceof HttpErrorResponse) {
			if (hasApiError(error.error)) {
				errorOutput.setApiError(error.error);
			} else if (hasApiError(error.error?.data)) {
				errorOutput.setApiError(error.error?.data);
			} else if ('data' in error && hasApiError(error.data)) {
				errorOutput.setApiError(error.data);
			} else {
				errorOutput.alert = `${error.message}\n${error.error}`;
			}
		} else if (error.message) {
			errorOutput.alert = `${error.message}`;
			errorOutput.stack = error.stack;
		} else {
			errorOutput.alert = `${error.toString()}`;
			errorOutput.stack = error.stack;
		}

		this.toasterService.error(errorOutput.alert, errorOutput.title, { onActivateTick: true });

		let errorLog = errorOutput.alert;
		if (errorOutput.stack)
			errorLog += '\n' + errorOutput.stack;
	}

	private get toasterService(): ToasterService {
		return this.injector.get(ToasterService);
	}
}
