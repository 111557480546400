import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
	selector: 'dg-busy, [dg-busy]',
	templateUrl: './busy.html',
	styleUrls: ['./busy.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Busy implements OnDestroy {
	@HostBinding('class.dg-busy-loading') loading = false;
	@HostBinding('class.dg-busy-container') isLocal = true;
	//diameter$: Observable<number>;

	private loaderCounter = 0;
	private readonly destroy$ = new Subject<void>();

	constructor(
		private readonly cdr: ChangeDetectorRef
	) {
		// this.diameter$ = interval(50).pipe(
		// 	startWith(0),
		// 	map(() => getComputedStyle(elementRef.nativeElement)),
		// 	distinctUntilChanged((prev, cur) => prev.height === cur.height && prev.width === cur.width),
		// 	map(style => Math.min(100, style.height ? parseInt(style.height, 10) : 0, style.width ? parseInt(style.width, 10) : 0))
		// );
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	show(): void {
		if (this.loaderCounter === 0) {
			this.loading = true;
			this.cdr.markForCheck();
		}
		this.loaderCounter++;
	}

	hide(): void {
		if (!this.loading) { return; }

		this.loaderCounter = Math.max(0, this.loaderCounter - 1);
		if (this.loaderCounter === 0) {
			this.loading = false;
			this.cdr.markForCheck();
		}
	}
}
