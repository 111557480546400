<div class="drop-down" [ngClass]="{'drop-down--active': isOpen, '': !isOpen}">
	<button type="button" id="dropDown" class="btn btn--primary btn--add" title="{{title}}" (click)="toggleDropDown()">
		&#x2b;
	</button>
	<div class="drop-down__menu-box">
		<ul class="drop-down__menu">
			<li class="drop-down__item" (click)="onAddButtonClicked(true)">{{item1_title}}</li>
		</ul>
		<ul class="drop-down__menu">
			<li class="drop-down__item" (click)="onAddButtonClicked(false)">{{item2_title}}</li>
		</ul>
	</div>
</div>
