<div class="grid">
	<div class="grid__header">
		<div *ngFor="let col of columns;" class="grid__header-cell disable-select {{col.headerClass}}"
			 (click)="onHeaderClicked($event, col)">
			<ng-container [ngSwitch]="col.type">
				<ng-container *ngSwitchCase="columnType.Regular">
					<ng-template *ngIf="col.templateRef" [ngTemplateOutletContext]="{ $implicit: col }"
								 [ngTemplateOutlet]="col.templateRef"></ng-template>

					<div *ngIf="!col.templateRef" class="grid__header-cell disable-select" [ngStyle]="col.style">
						<span [innerHTML]="col.text"></span>
						<i *ngIf="col.sortDirection === false" class="fa fa-sort-desc grid__header-cell--sorting"
						   aria-hidden="true"></i>
						<i *ngIf="col.sortDirection === true" class="fa fa-sort-asc grid__header-cell--sorting"
						   aria-hidden="true"></i>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="columnType.Select">
					<dg-smartcheckbox [mode]="selectionMode" (modeChanged)="onSelectionModeChanged($event)">
					</dg-smartcheckbox>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div class="grid__body">
		<div *ngFor="let item of finalRows;trackBy:trackById" class="grid__body-row {{item.class}}">

			<div *ngFor="let col of columns;trackBy:trackByText" class="grid__body-row-cell {{col.class}}"
				 (click)="onRowClick(item, col)">

				<ng-container [ngSwitch]="col.type">
					<ng-container *ngSwitchCase="columnType.Regular">
						<ng-template *ngIf="col.cellTemplate" [ngTemplateOutletContext]="{ $implicit: item }"
									 [ngTemplateOutlet]="col.cellTemplate"></ng-template>

						<div *ngIf="!col.cellTemplate">
							<span [innerHTML]="col.textProvider(item)"></span>
						</div>

					</ng-container>
					<ng-container *ngSwitchCase="columnType.Select">

						<div *ngIf="!item.isBusy" class="grid__body-row-cell--single" [ngClass]="{'checkbox--checked':item.isSelected, 'checkbox':!item.isSelected}" (click)="onSelectionChanged(item, $event)">
						<input class="checkbox-label" type="checkbox" [(ngModel)]="item.isSelected" />
						<label class="checkbox-label"> </label>
						</div>

						<div *ngIf="item.isBusy" class="grid__body-row-cell--single">
							<i class="fa fa-spinner fa-spin"></i>
						</div>

					</ng-container>

				</ng-container>

			</div>

		</div>
		<div *ngIf="(!finalRows || finalRows.length === 0) && tooMuchData !== true"
			 class="grid__body-row grid__body-row--empty">
			No Data to Display
		</div>
		<div *ngIf="tooMuchData === true" class="grid__body-row grid__body-row--empty">
			Too many records to display on page
		</div>
	</div>

	<div class="grid__footer" [ngClass]="{ 'hidden': hidePagination }">
		<div class="grid__page-size">
			<label class="grid__page-size-label">Show</label> <select class="dg0-select grid__page-size-select"
																	  [(ngModel)]="pageSize"
																	  (change)="onPageSizeChanged()">
			<option *ngFor="let opt of pageSizeOptions">{{opt}}</option>
		</select>
		</div>
		<dg-gridinfo [from]="infoFrom" [to]="infoTo" [totalFiltered]="infoTotalFiltered" [isFiltered]="infoFiltered"
					 [total]="infoTotal" [selected]="infoSelected"></dg-gridinfo>
		<dg-pagination #ctrPagination [current]="page" [total]="total" [size]="pageSize"
					   [hidePaginationIfSingle]="hidePaginationIfSingle"
					   (pageChanged)="onPageChanged($event)"></dg-pagination>
	</div>
</div>
