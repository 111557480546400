import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'dg-smartcheckbox',
	templateUrl: './dg-smart-checkbox.component.html',
	styleUrls: ['./dg-smart-checkbox.component.sass']
})
export class DgSmartCheckboxComponent {
	
	@Input() mode: DgSmartCheckboxState = DgSmartCheckboxState.None;
	
	@Output() modeChanged: EventEmitter<DgSmartCheckboxState> = new EventEmitter<DgSmartCheckboxState>();
	
	isSelected = false;
	
	modeType = DgSmartCheckboxState;
	
	ngOnChanges(changes: any): void {
		switch (changes.mode.currentValue) {
		case DgSmartCheckboxState.None:
			this.isSelected = false;
			break;
		case DgSmartCheckboxState.Some:
			this.isSelected = true;
			break;
		case DgSmartCheckboxState.All:
			this.isSelected = true;
			break;
		}
	}
	
	onClicked(event): void {
		event.stopPropagation();
		
		switch (this.mode) {
		case DgSmartCheckboxState.None:
			this.changeState(DgSmartCheckboxState.All);
			break;
		case DgSmartCheckboxState.Some:
			this.changeState(DgSmartCheckboxState.None);
			break;
		case DgSmartCheckboxState.All:
			this.changeState(DgSmartCheckboxState.None);
		}
		
		this.modeChanged.emit(this.mode);
	}
	
	changeState(newMode: DgSmartCheckboxState): void {
		switch (newMode) {
		case DgSmartCheckboxState.None:
			this.isSelected = false;
			break;
		case DgSmartCheckboxState.Some:
			this.isSelected = true;
			break;
		case DgSmartCheckboxState.All:
			this.isSelected = true;
			break;
		}
		
		this.mode = newMode;
	}
	
}

export enum DgSmartCheckboxState {
	None = 1,
	Some,
	All
}
